import jsSHA from 'jssha/dist/sha256';

function hashValue(value) {
    if (!value) {
        return '';
    }
    const shaObj = new jsSHA("SHA-256", "TEXT");
    shaObj.update(normalizeValue(value));
    return shaObj.getHash("HEX");
}

function normalizeValue(value) {
    return value?.trim().toLowerCase();
}

function ShoppingCartUserStateEvents(tagData, addStoredData, getStoredData) {
    const {
        email,
        phone
    } = tagData;

    addStoredData({
        userLogState: {
            ...getStoredData(['userLogState']).userLogState,
            normalizedGuestHashedEmail: hashValue(email),
            normalizedGuestHashedPhone: hashValue(phone),
            normalizedEmail: normalizeValue(email),
            unNormalizedEmail: email,
        }
    });
}

export { ShoppingCartUserStateEvents };
